@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

.App {
  /* text-align: center; */
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.header-top-left-logo {
  width: 100%;
  text-align: center;
  padding: 5px;
}

.header-top-left-logo img {
  height: 50px !important;
}

.ant-menu-inline-collapsed .ant-menu-item-icon {
  vertical-align: inherit !important;
}

.page-title .add-event {
  padding: 5px 30px;
}

.page-title-head {
  font-size: 1.2rem;
  font-weight: bold;
}

.action-button {
  padding: 5px 15px;
}

.notification-popover {
  min-width: 350px;
  max-width: 350px;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  width: auto;
}

.notification-popover1 {
  max-height: 90vh;
  overflow-y: auto;
}

.notification-popover .ant-popover-title {
  padding: 10px 16px 10px;
}

.ant-picker-input>input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input[disabled],
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.75) !important;
}

.styled-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 0px 5px 5px 5px;
  opacity: 1;
  padding: 20px;
}

.ant-modal-content{
  border-radius: 10px;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.name_nowrap .ant-table-tbody>tr>td:first-child {
  white-space: nowrap;
}

.name_nowrap .ant-table-thead>tr>th:last-child {
  min-width: 95px;
}

.ant-form-item-label > label{
  color: #5d5f68;
  font-weight: 500;
  
}
@media (max-width: 321px) {
  .ant-form-item-label > label {
    height: 55px;
}
}

.ant-input:focus, .ant-input-focused {
  border-color: #2D53DA;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-input:hover {
  border-color: #2D53DA;
  border-right-width: 1px;
}

.ant-picker:hover, .ant-picker-focused {
    border-color: #2D53DA;
    border-right-width: 1px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #2D53DA;
  border-right-width: 1px;
  z-index: 1;
}

.ant-select:hover(.ant-select-customize-input) .ant-select-focused {
  position: relative;
  background-color: #fff;
  border: 1px solid #2D53DA;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: 1px solid #2D53DA;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
}

.ant-select-selector:hover {
  border-color: #2D53DA !important; /* Change to the desired hover border color */
}

/* Appraisal css */
.common_title {
  font: normal normal medium 20px/25px Circular Std;
}

.form_builder.main_container {
  background: #EDF7FF;
}

.form_builder .bg_light {
  background-color: #FFFFFF;
}

.Status-placeholder{
  color: rgba(0, 0, 0, 0.374);
  font-weight: 400;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.expense-bg{
  background-color: #9a97971c;
}

.error{
  color: red;
}

@media (min-width: 1000px) {
  .border-left {
    border-right: 1px solid #9a979729; 
  }
}





.custom-menu-item {
  display: flex;
  height: 100%;
  width: 90px;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.travel-icon {
  font-size: 18px; /* Adjust as needed */
 
}

.ant-menu-vertical  .ant-menu-submenu  .ant-menu-submenu-title{
  height: auto !important;
  line-height: 30px;
  font-size: 12px;
  margin-block: 0 !important;
}

.ant-menu-vertical .ant-menu-item .ant-menu-item-title{
  margin-block: 0 !important;
}

.ant-menu-dark .ant-menu-item{
  margin-block: 0 !important;
}
.ant-menu-inline, .ant-menu-vertical {
  border-right: 1px solid transparent !important;
}



.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #ffffff;
  
  border-radius: 10px;


}

.ant-menu.ant-menu-dark .ant-menu-sub {
  padding: 10px;
  border-radius: 10px;
}
.ant-menu-vertical.ant-menu-sub  .ant-menu-item{
  background: #ffffff;
  height: auto !important;
  padding-top:0 !important;
  line-height: 40px !important;

}
.ant-menu-vertical.ant-menu-sub :hover{
  background-color: #E3E8FC !important;
}

.ant-menu-item a {
  color: #1D4174 !important;
  /* font-weight: 600; */

}

.ant-menu-item svg {
  color: #1D4174 !important;
  /* fill: #1D4174; */
}
.ant-menu-item a :hover{
  color: #1D4174 !important;
 
}
.ant-menu-dark .ant-menu-item{
  padding-top: 10px;
  height: 60px !important;
  line-height: 23px !important;
  font-size: 12px;
}

/* .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
} */